<template>
    <div class="page">
        <!-- 1=实物 -->
        <div class="orderDetail" v-if="status==1">
            <div class="matterTitle">请选择邮寄地址</div>
            <div class="matterAddress">
                <div class="matterAddressInfo">
                    <div class="top van-ellipsis">{{productInfo.order.name}} <span>{{productInfo.order.phone}}</span></div>
                    <div class="desc van-multi-ellipsis--l2">
                        {{productInfo.order.province+productInfo.order.city+productInfo.order.area+productInfo.order.address}}
                    </div>
                </div>
                <div class="matterAddressImage">
                    <img src="../assets/icon.png"/>
                </div>
            </div>
            <div class="matterTitle">礼品订单信息</div>
            <div class="matterGoods">
                <div class="matterGoodsBox">
                    <div class="matterGoodsLeft">
                        <img :src="productInfo.goods.goods_image" :alt="productInfo.goods.goods_name"/>
                    </div>
                    <div class="matterGoodsBody">
                        <div class="top van-ellipsis">{{productInfo.goods.goods_name}}</div>
                        <div class="desc van-multi-ellipsis--l2">{{productInfo.goods.remarks}}</div>
                        <div class="price">市场价:￥<span>{{productInfo.goods.price}}</span></div>
                    </div>
                </div>
                <div class="matterGoodsTime">领取时间: {{productInfo.order.createtime}}</div>
            </div>
            <div class="matterTitle">物流详情</div>
            <div class="matterSend" v-if="productInfo.logistics">
                <div class="name">快递公司:</div>
                <div class="number">快递单号:</div>
            </div>
            <div class="matterSendNone" v-else>暂无快递信息</div>
        </div>
        <!-- 2=电子卡券 -->
        <div class="orderDetail" v-if="status==2">
            <div class="body">
                <div class="image">
                    <img :src="productInfo.goods.goods_image" :alt="productInfo.goods.goods_image"/>
                </div>
                <div class="title">{{productInfo.goods.goods_name}}</div>
                <div class="box">
                    <div class="cardText">卡号：{{productInfo.order.cardnumber}} <span>长按复制</span></div>
                    <div v-if="productInfo.order.carmi" class="cardText">卡密：{{productInfo.order.carmi}} <span>长按复制</span></div>
                </div>
                <div class="line2"/>
                <div class="box">
                    <div class="note">领取时间: {{productInfo.order.createtime}}</div>
                    <div class="note">有效期至: {{productInfo.order.endtime}}</div>
                    <div class="note">订单编号: {{productInfo.order.order_on}}</div>
                </div>
            </div>
            <div class="descTitle">兑换品介绍</div>
            <div class="descBody">{{productInfo.goods.goods_Introduction}}</div>
            <div class="line"></div>
            <div class="descBody" v-html="productInfo.goods.goods_content"/>
            <div class="line"></div>
            <div class="bottom">
                <BottomCom/>
            </div>
        </div>
        <!-- 3=第三方票务 -->
        <div class="orderDetail" v-if="status==3">
            <div class="body">
                <div class="image">
                    <img :src="productInfo.goods.goods_image" :alt="productInfo.goods.goods_image"/>
                </div>
                <div class="title">{{productInfo.goods.goods_name}}</div>
                <div class="line2"/>
                <div class="box">
                    <div class="note">预约手机: {{productInfo.order.phone}}</div>
                    <div class="note">领取时间: {{productInfo.order.createtime}}</div>
                    <div class="note">订单编号: {{productInfo.order.order_on}}</div>
                </div>
            </div>
            <div class="descTitle">兑换品介绍</div>
            <div class="descBody">{{productInfo.goods.goods_Introduction}}</div>
            <div class="line"></div>
            <div class="descBody" v-html="productInfo.goods.goods_content"/>
            <div class="line"></div>
            <div class="bottom">
                <BottomCom/>
            </div>
        </div>
        <!-- 4=下午茶 5=酒店权益 -->
        <div class="orderDetail" v-if="status==4 || status==5">
            <div class="body">
                <div class="image">
                    <img :src="productInfo.goods.goods_image" :alt="productInfo.goods.goods_image"/>
                </div>
                <div class="title">{{productInfo.goods.goods_name}}</div>
                <div class="box">
                    <div class="cardText">卡号：{{productInfo.order.cardnumber}} <span>长按复制</span></div>
                    <div v-if="productInfo.order.carmi" class="cardText">卡密：{{productInfo.order.carmi}} <span>长按复制</span></div>
                </div>
                <div class="line2"/>
                <div class="box">
                    <div class="note">领取时间: {{productInfo.order.createtime}}</div>
                    <div class="note">有效期至: {{productInfo.order.endtime}}</div>
                    <div class="note">订单编号: {{productInfo.order.order_on}}</div>
                    <div class="note">请访问此网址查看支持门店:</div>
                    <a class="note" href="https://h5-gate.bestdo.com/showCardProductList?c=3546">https://h5-gate.bestdo.com/showCardProductList?c=3546</a>
                </div>
            </div>
            <div class="descTitle">兑换品介绍</div>
            <div class="descBody">{{productInfo.goods.goods_Introduction}}</div>
            <div class="line"></div>
            <div class="descBody" v-html="productInfo.goods.goods_content"/>
            <div class="line"></div>
            <div class="bottom">
                <BottomCom/>
            </div>
        </div>
        <!-- 6=景点门票 -->
        <div class="orderDetail" v-if="status==6">
            <div class="body">
                <div class="image">
                    <img :src="productInfo.goods.goods_image" :alt="productInfo.goods.goods_image"/>
                </div>
                <div class="title">{{productInfo.goods.goods_name}}</div>
                <div class="line2"/>
                <div class="box">
                    <div class="title1">预约人1信息</div>
                    <div class="tr">
                        <span>顾客姓名</span>
                        <span>{{orderInfo.name}}</span>
                    </div>
                    <div class="tr">
                        <span>手机号码</span>
                        <span>{{orderInfo.phone}}</span>
                    </div>
                    <div class="tr">
                        <span>证件号码</span>
                        <span>{{orderInfo.idcard}}</span>
                    </div>
                    <div class="title1">预约人2信息</div>
                    <div class="tr">
                        <span>顾客姓名</span>
                        <span>{{orderInfo.name1}}</span>
                    </div>
                    <div class="tr">
                        <span>手机号码</span>
                        <span>{{orderInfo.phone1}}</span>
                    </div>
                    <div class="tr">
                        <span>证件号码</span>
                        <span>{{orderInfo.idcard1}}</span>
                    </div>
                    <div class="title1">预约人3信息(婴儿或儿童)</div>
                    <div class="tr">
                        <span>顾客姓名</span>
                        <span>{{orderInfo.name2}}</span>
                    </div>
                    <div class="tr">
                        <span>证件号码</span>
                        <span>{{orderInfo.idcard2}}</span>
                    </div>
                    <div class="title1">到店信息</div>
                    <div class="tr">
                        <span>到店时间</span>
                        <span>{{orderInfo.time}}</span>
                    </div>
                    <div class="tr">
                        <span>景点</span>
                        <span>{{orderInfo.goods_name}}</span>
                    </div>
                </div>
                <div class="line2"/>
                <div class="box">
                    <div class="title1">备注</div>
                    <div class="note">{{productInfo.goods.elaboration2 || '无'}}</div>
                </div>
            </div>
            <div class="descTitle">兑换品介绍</div>
            <div class="descBody">{{productInfo.goods.goods_Introduction}}</div>
            <div class="line"></div>
            <div class="descBody" v-html="productInfo.goods.goods_content"/>
            <div class="line"></div>
            <div class="bottom">
                <BottomCom/>
            </div>
        </div>
        <!-- 7=接送机 -->
        <div class="orderDetail" v-if="status==7">
            <div class="body">
                <div class="image">
                    <img :src="productInfo.goods.goods_image" :alt="productInfo.goods.goods_image"/>
                </div>
                <div class="title">{{productInfo.goods.goods_name}}</div>
                <div class="line2"/>
                <div class="box">
                    <div class="title1">预约人信息</div>
                    <div class="tr">
                        <span>顾客姓名</span>
                        <span>{{orderInfo.name}}</span>
                    </div>
                    <div class="tr">
                        <span>手机号码</span>
                        <span>{{orderInfo.phone}}</span>
                    </div>
                    <div class="title1">预约信息</div>
                    <div class="tr">
                        <span>行程</span>
                        <span>{{orderInfo.type === 1?'接机':'送机'}}</span>
                    </div>
                    <div class="tr">
                        <span>出发时间</span>
                        <span>{{orderInfo.time}}</span>
                    </div>
                    <div class="tr">
                        <span>机场</span>
                        <span>{{orderInfo.airport}}</span>
                    </div>
                    <div class="tr">
                        <span>航班号</span>
                        <span>{{orderInfo.number}}</span>
                    </div>
                    <div class="tr">
                        <span>乘坐人数</span>
                        <span>{{orderInfo.num}}</span>
                    </div>
                    <div class="tr">
                        <span>接机地址</span>
                        <span>{{orderInfo.pick_upaddres}}</span>
                    </div>
                </div>
                <div class="line2"/>
                <div class="box">
                    <div class="title1">备注</div>
                    <div class="note">{{productInfo.goods.elaboration2 || '无'}}</div>
                </div>
            </div>
            <div class="descTitle">兑换品介绍</div>
            <div class="descBody">{{productInfo.goods.goods_Introduction}}</div>
            <div class="line"></div>
            <div class="descBody" v-html="productInfo.goods.goods_content"/>
            <div class="line"></div>
            <div class="bottom">
                <BottomCom/>
            </div>
        </div>
        <div class="footer">
            <span @click="goHome">我的权益</span>
            <span @click="goOrder">兑换中心</span>
        </div>
    </div>
</template>

<script>
    import _ from "lodash";
    import {getOrderdetails, submitProduct} from "../api/data";
    import {Toast} from "vant";
    import BottomCom from "../components/BottomCom";

    export default {
        name: "OrderDetail",
        components: {BottomCom},
        data: function () {
            return {
                submitModal: false,
                productInfo: {},
                orderInfo: null,
                status: 0,//1=实物,2=电子卡券,3=第三方票务,4=下午茶,5=酒店权益,6=景点门票,7=接送机
            }
        },
        async beforeMount() {
            await this.queryOrderDetailInfo();
        },
        methods: {
            async goOrder() {
                await this.$router.push('/Order')
            },
            async goHome() {
                await this.$router.push('/')
            },
            async queryOrderDetailInfo() {
                let param = {
                    order_id: _.toInteger(this.$route.query.orderId)
                };
                let res = await getOrderdetails(param);
                if (_.toInteger(res.code) === 1) {
                    //  订单类型:speciesdata  1=实物,2=电子卡券,3=第三方票务,4=下午茶,5=酒店权益,6=景点门票,7=接送机
                    console.log('订单详情=', res.data);
                    console.log('status=', res.data.goods.speciesdata);
                    if (_.toInteger(res.data.goods.speciesdata) === 1) {
                        this.addressInfo = {};
                        this.productItem = {};
                    }

                    this.productInfo = res.data;
                    this.status = this.productInfo.goods.speciesdata
                    this.orderInfo = res.data.order

                } else {
                    Toast.fail(res.msg);
                }
            },
            closeSubmitModal() {
                this.submitModal = false;
            },
            async goBack() {
                await this.$router.back();
            },
            async sureSubmitModal() {
                let productInfo = this.productInfo;
                let goodsData = _.toInteger(productInfo.speciesdata);
                switch (goodsData) { //1=实物,2=电子卡券,3=第三方票务,4=下午茶,5=酒店权益,6=景点门票,7=接送机
                    case 1: //实物
                        localStorage.setItem("productItem", JSON.stringify(productInfo));
                        await this.$router.push('MatterSubmit');
                        break;
                    case 2: //电子卡券
                        console.log(goodsData);
                        let param = {
                            id: productInfo.id,
                            goods_id: productInfo.goods_id
                        };
                        console.log(param);
                        let res = await submitProduct(param);
                        if (_.toInteger(res.code) === 1) {
                            await this.$router.push({name: 'Success', query: {orderId: res.data.order_on}});
                        } else {
                            Toast.fail(res.msg);
                        }
                        break;
                    case 3: //第三方票务
                        break
                    default:
                        Toast.fail("异常");
                }
            }
        }
    }
</script>

<style scoped>
    .orderDetail {
        display: flex;
        flex-direction: column;
        width: 690px;
        flex: 1;
        overflow-x: hidden;
        justify-content: flex-start;
        align-items: center;
        padding: 24px 24px 148px 24px;
        box-sizing: border-box;
        overflow-y: auto;
    }

    .orderDetail .matterTitle {
        width: 642px;
        font-size: 30px;
        font-weight: bold;
        line-height: 42px;
        color: #A05923;
        margin-bottom: 15px;
    }

    .orderDetail .matterAddress {
        width: 642px;
        box-sizing: border-box;
        border: 2px solid #D4CECB;
        background: linear-gradient(131deg, #3A3D43 0%, #222529 100%);
        border-radius: 12px;
        padding: 35px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;
    }

    .orderDetail .matterAddress .matterAddressInfo {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .orderDetail .matterAddress .matterAddressInfo .top {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-size: 30px;
        color: #E8C8AF;
    }

    .orderDetail .matterAddress .matterAddressInfo .top span {
        margin-left: 20px;
    }

    .orderDetail .matterAddress .matterAddressInfo .desc {
        margin-top: 15px;
        font-size: 26px;
        font-weight: 400;
        line-height: 38px;
        color: #B19485;
    }

    .orderDetail .matterAddress .matterAddressImage {
        margin-left: 20px;
        width: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .orderDetail .matterAddress .matterAddressImage img {
        width: 8px;
        height: 18px;
    }

    .orderDetail .matterGoods {
        width: 642px;
        box-sizing: border-box;
        border: 2px solid #D4CECB;
        background: linear-gradient(131deg, #3A3D43 0%, #222529 100%);
        border-radius: 12px;
        padding: 35px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 40px;
    }

    .orderDetail .matterGoods .matterGoodsBox {
        width: 578px;
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .orderDetail .matterGoods .matterGoodsLeft {
        width: 158px;
        height: 158px;
        border-radius: 8px;
        background: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    .orderDetail .matterGoods .matterGoodsLeft img {
        max-width: 100%;
        max-height: 100%;
    }

    .orderDetail .matterGoods .matterGoodsBody {
        margin-left: 20px;
        display: flex;
        width: 100%;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        height: 168px;
        overflow: hidden;
    }

    .orderDetail .matterGoods .matterGoodsBody .top {
        width: 100%;
        height: 38px;
        font-size: 27px;
        font-weight: 500;
        color: #E8C8AF;
    }

    .orderDetail .matterGoods .matterGoodsBody .desc {
        width: 100%;
        margin-top: 10px;
        font-size: 22px;
        font-weight: 500;
        line-height: 30px;
        overflow: hidden;
        color: #B19485;
    }

    .orderDetail .matterGoods .matterGoodsBody .price {
        margin-top: 20px;
        width: 100%;
        font-size: 20px;
        font-weight: 400;
        color: #E8C8AF;
    }

    .orderDetail .matterGoods .matterGoodsBody .price span {
        font-size: 29px;
        font-weight: bold;
        color: #E8C8AF;
    }

    .orderDetail .matterGoods .matterGoodsTime {
        margin-top: 38px;
        padding-top: 25px;
        border-top: 1px solid #B19485;
        width: 578px;
        font-size: 24px;
        font-weight: 400;
        color: #E8C8AF;
    }

    .orderDetail .matterSend {
        width: 642px;
        box-sizing: border-box;
        border: 2px solid #D4CECB;
        background: linear-gradient(131deg, #3A3D43 0%, #222529 100%);
        border-radius: 12px;
        padding: 35px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 40px;
    }

    .orderDetail .matterSendNone {
        width: 642px;
        box-sizing: border-box;
        border: 2px solid #D4CECB;
        background: linear-gradient(131deg, #3A3D43 0%, #222529 100%);
        border-radius: 12px;
        padding: 35px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 40px;
        font-size: 30px;
        font-weight: 500;
        color: #E8C8AF;
    }

    .orderDetail .matterSend .name {
        width: 568px;
        font-size: 30px;
        font-weight: 500;
        color: #E8C8AF;
    }

    .orderDetail .matterSend .number {
        margin-top: 15px;
        width: 568px;
        font-size: 26px;
        font-weight: 400;
        color: #B19485;
        padding-bottom: 30px;
        border-bottom: 1px solid #B19485;
    }

    .orderDetail .body {
        width: 642px;
        border: 2px solid #D4CECB;
        background: linear-gradient(131deg, #3A3D43 0%, #23262B 100%);
        border-radius: 12px;
        padding: 40px;
        box-sizing: border-box;
        color: #E3C096;
    }

    .orderDetail .body .image {
        width: 562px;
        height: auto;
        min-height: 582px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .orderDetail .body .image img {
        max-width: 562px;
        max-height: 562px;
    }

    .orderDetail .body .title {
        width: 562px;
        font-size: 36px;
        font-weight: 500;
        color: #E3C096;
        margin-top: 45px;
    }

    .orderDetail .body .box {
        width: 562px;
    }

    .orderDetail .body .box .cardText {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 562px;
        font-size: 28px;
        font-weight: 500;
        color: #B19485;
        padding: 5px 0;
        margin-top: 20px;
    }

    .orderDetail .body .box .cardText span {
        height: 44px;
        border: 1px solid #B19485;
        border-radius: 22px;
        font-size: 24px;
        color: #B19485;
        padding: 0 15px;
        margin-left: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .orderDetail .body .box .title1 {
        width: 562px;
        font-size: 28px;
        font-weight: 500;
        color: #E3C096;
        padding: 10px 0;
        margin-bottom: 20px;
    }

    .orderDetail .body .box .note {
        width: 562px;
        font-size: 24px;
        font-weight: 500;
        color: #B19485;
        margin: 10px 0;
    }

    .orderDetail .body .box .tr {
        width: 562px;
        margin-bottom: 15px;
        display: flex;
        flex-direction: row;
    }

    .orderDetail .body .box .tr span {
        display: flex;
        font-size: 24px;
        font-weight: 500;
        color: #B19485;
    }

    .orderDetail .body .box .tr span:first-child {
        width: 120px;
    }

    .orderDetail .body .box .tr span:last-child {
        margin-left: 40px;
    }

    .orderDetail .body .line2 {
        margin: 34px 0;
        width: 600px;
        height: 2px;
        background: linear-gradient(90deg, rgba(239, 194, 142, 0) 0%, #F0C595 49%, rgba(239, 194, 141, 0) 100%);
    }

    .orderDetail .descTitle {
        width: 642px;
        margin-top: 40px;
        font-size: 28px;
        font-weight: bold;
        line-height: 34px;
        color: #272A2F;
    }

    .orderDetail .descBody {
        width: 642px;
        margin-top: 10px;
        font-size: 24px;
        font-weight: 400;
        line-height: 48px;
        color: #272A2F;
        overflow-x: hidden;
    }

    .orderDetail .descBody img {
        max-width: 100%;
    }

    .orderDetail .line {
        width: 600px;
        height: 2px;
        background: linear-gradient(90deg, rgba(37, 40, 45, 0) 0%, #25282D 49%, rgba(35, 38, 43, 0) 100%);
        margin: 50px 0;
    }

    .page .footer {
        position: fixed;
        bottom: 0;
        background: #FFFFFF;
        width: 690px;
        height: 120px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
    }

    .page .footer span {
        flex: 1;
        margin: 0 20px;
        height: 80px;
        border-radius: 40px;
        font-size: 30px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .page .footer span:first-child {
        border: 2px solid #222529;
        color: #212429;
    }

    .page .footer span:last-child {
        border: 2px solid #707070;
        background: linear-gradient(125deg, #3A3D43 0%, #202327 100%);
        color: #E2BE93;
    }
</style>
